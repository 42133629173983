import types from '../types';

const initialState = {
  uptimes: {
    list: [],
    isLoading: false,
    error: ''
  },
  selectedDay: new Date(),
  downtimesOfDay: {
    list: [],
    isLoading: false,
    error: ''
  }
};

const updateSubState = key => (fullState, changes) => {
  return {
    ...fullState,
    [key]: {
      ...fullState[key],
      ...changes
    }
  };
};

const updateUptimes = updateSubState('uptimes');
const updateDowntimesOfDay = updateSubState('downtimesOfDay');

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.FIND_INSTANCE_UPTIMES_REQUEST:
      return updateUptimes(state, {
        isLoading: true,
        error: ''
      });

    case types.FIND_INSTANCE_UPTIMES_SUCCESS:
      return updateUptimes(state, {
        list: action.payload,
        isLoading: false
      });

    case types.FIND_INSTANCE_UPTIMES_ERROR:
      return updateUptimes(state, {
        isLoading: false,
        error: action.payload
      });

    case types.FIND_INSTANCE_DOWNTIMES_OF_DAY_REQUEST:
      return updateDowntimesOfDay(state, {
        isLoading: true,
        error: ''
      });

    case types.FIND_INSTANCE_DOWNTIMES_OF_DAY_SUCCESS:
      return updateDowntimesOfDay(state, {
        list: action.payload,
        isLoading: false
      });

    case types.FIND_INSTANCE_DOWNTIMES_OF_DAY_ERROR:
      return updateDowntimesOfDay(state, {
        isLoading: false,
        error: action.payload
      });

    case types.SELECT_INSTANCE_DAY:
      return {
        ...state,
        selectedDay: action.payload
      };

    default:
      return state;
  }
}
