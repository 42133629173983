export default {
  FIND_INSTANCE_UPTIMES_REQUEST: 'FIND_INSTANCE_UPTIMES_REQUEST',
  FIND_INSTANCE_UPTIMES_SUCCESS: 'FIND_INSTANCE_UPTIMES_SUCCESS',
  FIND_INSTANCE_UPTIMES_ERROR: 'FIND_INSTANCE_UPTIMES_ERROR',

  FIND_INSTANCE_DOWNTIMES_OF_DAY_REQUEST: 'FIND_INSTANCE_DOWNTIMES_OF_DAY_REQUEST',
  FIND_INSTANCE_DOWNTIMES_OF_DAY_SUCCESS: 'FIND_INSTANCE_DOWNTIMES_OF_DAY_SUCCESS',
  FIND_INSTANCE_DOWNTIMES_OF_DAY_ERROR: 'FIND_INSTANCE_DOWNTIMES_OF_DAY_ERROR',

  SELECT_INSTANCE_DAY: 'SELECT_INSTANCE_DAY'
};
