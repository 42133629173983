import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Modal, Form, Input, Tag, Table } from 'antd';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import ipRegex from 'ip-regex';
import * as InstanceActionCreators from '../../../store/actions/instances';
import EditableFormTable from '../../utils/EditableFormTable';

const CreateInstanceForm = styled(Form)``;

export class Instances extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      createModalIsOpen: false,
      userId: '',
      editingId: '',
      columns: [
        {
          title: t('instances:fields:name'),
          dataIndex: 'name',
          editable: true,
          render: (text, record) => (
            <Link to={`/users/${this.state.userId}/instances/${record.id}`}>{text}</Link>
          )
        },
        {
          title: t('instances:fields:ip'),
          dataIndex: 'ip',
          editable: true
        },
        {
          title: t('instances:fields:agent_version'),
          dataIndex: 'agentVersion',
          editable: false
        },
        {
          title: t('instances:fields:created_at'),
          dataIndex: 'createdAt',
          editable: false
        },
        {
          title: t('instances:fields:status'),
          dataIndex: 'isOnline',
          editable: false,
          render: (text, record) => {
            return record.isOnline ? (
              <Tag color="green">{t('instances:fields:status_online')}</Tag>
            ) : (
              <Tag color="red">{t('instances:fields:status_offline')}</Tag>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    this.setState(
      {
        userId: this.props.match.params.userId || this.props.user.id
      },
      () => {
        this.props.getInstances(this.state.userId);
      }
    );
  }

  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      this.setState({ createModalIsOpen: false });
      this.props.createInstance({
        name: values.name,
        ip: values.ip,
        user_id: this.state.userId
      });
    });
  }

  renderReadOnly = () => {
    const { list, isLoading } = this.props;
    return (
      <Table
        loading={isLoading}
        dataSource={list}
        rowKey="id"
        columns={this.state.columns}
        pagination={false}
      />
    );
  };

  renderAdmin = () => {
    const {
      t,
      list,
      isLoading,
      form: { getFieldDecorator }
    } = this.props;
    return (
      <div>
        <Button
          type="primary"
          icon="plus"
          style={{ marginBottom: 15 }}
          onClick={() => this.setState({ createModalIsOpen: true })}
        >
          {t('instances:create_form:title')}
        </Button>
        <Modal
          width="900px"
          title={t('instances:create_form:title')}
          visible={this.state.createModalIsOpen}
          onOk={() => this.handleSubmit()}
          confirmLoading={false}
          onCancel={() => this.setState({ createModalIsOpen: false })}
        >
          <CreateInstanceForm
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            onSubmit={this.handleSubmit}
          >
            <Form.Item label={t('instances:fields:name')}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: t('instances:create_form:name_error') }]
              })(<Input placeholder={t('instances:fields:name')} autoComplete="name" />)}
            </Form.Item>
            <Form.Item label={t('instances:fields:ip')}>
              {getFieldDecorator('ip', {
                rules: [
                  { required: true, message: t('instances:create_form:ip_error') },
                  {
                    validator: (rule, value, cb) => {
                      if (!ipRegex({ exact: true }).test(value)) {
                        return cb(false);
                      }
                      cb();
                    },
                    message: t('instances:create_form:ip_error')
                  }
                ]
              })(<Input type="text" placeholder={t('instances:fields:ip')} autoComplete="ip" />)}
            </Form.Item>
          </CreateInstanceForm>
        </Modal>

        <EditableFormTable
          loading={isLoading}
          columns={this.state.columns}
          data={list}
          editingId={this.state.editingId}
          onEditing={id => this.setState({ editingId: id })}
          onEdit={instance => this.props.updateInstance(instance)}
          onRemove={instance => this.props.removeInstance(instance)}
        />
      </div>
    );
  };

  render() {
    const { user } = this.props;
    return user.role === 'regular' ? this.renderReadOnly() : this.renderAdmin();
  }
}

const mapStateToProps = ({ instances: { list, error, isLoading }, auth: { user } }) => ({
  isLoading,
  error,
  list,
  user
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(InstanceActionCreators, dispatch);
};

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  Form.create({ name: 'createUser' })
)(Instances);
