import types from '../types';
import InstanceMetricsService from '../../services/InstanceMetricsService';
import handleError from '../utils/errors';

export const findUptimes = (instanceId, userId) => dispatch => {
  dispatch({
    type: types.FIND_INSTANCE_UPTIMES_REQUEST
  });
  return InstanceMetricsService.findUptimes(instanceId, userId)
    .then(uptimes => {
      return dispatch({
        type: types.FIND_INSTANCE_UPTIMES_SUCCESS,
        payload: uptimes.map(({ uptime, day }) => {
          return {
            date: new Date(day),
            uptime
          };
        })
      });
    })
    .catch(err => handleError(types.FIND_INSTANCE_UPTIMES_ERROR, err, dispatch));
};

export const findDowntimesOfDay = (date, instanceId) => dispatch => {
  dispatch({
    type: types.FIND_INSTANCE_DOWNTIMES_OF_DAY_REQUEST
  });
  return InstanceMetricsService.findDowntimesOfDay(date, instanceId)
    .then(downtimes => {
      return dispatch({
        type: types.FIND_INSTANCE_DOWNTIMES_OF_DAY_SUCCESS,
        payload: downtimes
      });
    })
    .catch(err => handleError(types.FIND_INSTANCE_DOWNTIMES_OF_DAY_ERROR, err, dispatch));
};

export const selectDay = (date, instanceId) => dispatch => {
  return findDowntimesOfDay(date, instanceId)(dispatch).then(() => {
    dispatch({
      type: types.SELECT_INSTANCE_DAY,
      payload: date
    });
  });
};
