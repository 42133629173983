import auth from './auth';
import users from './users';
import instances from './instances';
import uptimes from './instanceMetrics';
import errors from './errors';

export default {
  ...auth,
  ...users,
  ...instances,
  ...uptimes,
  ...errors
};
