import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { compose, omit } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { Button, Modal, Form, Input, Icon } from 'antd';
import styled from 'styled-components';
import * as UserActionCreators from '../../store/actions/users';
import EditableFormTable from '../utils/EditableFormTable';

const CreateUserForm = styled(Form)``;

export class Users extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    const columns = [
      {
        title: t('users:fields:login'),
        dataIndex: 'login',
        editable: true,
        render: (text, record) => <Link to={`/users/${record.id}/instances`}>{text}</Link>
      },
      {
        title: t('users:fields:created_at'),
        dataIndex: 'createdAt',
        editable: false
      }
    ];
    this.state = {
      createModalIsOpen: false,
      columns: columns,
      editingId: '',
      editingColumns: [
        ...columns.slice(0, 1),
        {
          title: props.t('users:fields:password'),
          dataIndex: 'password',
          editable: true
        }
      ]
    };
  }

  componentDidMount() {
    this.props.getUsers();
  }

  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      this.setState({ createModalIsOpen: false });
      this.props.createUser({
        login: values.login,
        password: values.password
      });
    });
  }

  edit(user) {
    // remove password if it's empty (we don't want to update it)
    const userToUpdate = user.password ? user : omit(['password'], user);
    this.props.updateUser(userToUpdate);
  }

  render() {
    const {
      t,
      list,
      isLoading,
      form: { getFieldDecorator }
    } = this.props;
    return (
      <div>
        <Button
          type="primary"
          icon="plus"
          style={{ marginBottom: 15 }}
          onClick={() => this.setState({ createModalIsOpen: true })}
        >
          {t('users:create_form:title')}
        </Button>
        <Modal
          width="900px"
          title={t('users:create_form:title')}
          visible={this.state.createModalIsOpen}
          onOk={() => this.handleSubmit()}
          confirmLoading={false}
          onCancel={() => this.setState({ createModalIsOpen: false })}
        >
          <CreateUserForm
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            onSubmit={this.handleSubmit}
          >
            <Form.Item label={t('users:fields:login')}>
              {getFieldDecorator('login', {
                rules: [{ required: true, message: t('users:create_form:login_error') }]
              })(<Input placeholder={t('users:fields:login')} autoComplete="new-login" />)}
            </Form.Item>
            <Form.Item label={t('users:fields:password')}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    min: 6,
                    message: t('users:create_form:password_error')
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon
                      type="lock"
                      style={{ color: 'rgba(0,0,0,.25)' }}
                      autoComplete="new-password"
                    />
                  }
                  type="password"
                  placeholder={t('users:fields:password')}
                />
              )}
            </Form.Item>
          </CreateUserForm>
        </Modal>
        <EditableFormTable
          loading={isLoading}
          editingId={this.state.editingId}
          onEditing={id => this.setState({ editingId: id })}
          columns={this.state[this.state.editingId === '' ? 'columns' : 'editingColumns']}
          data={list}
          onEdit={user => this.edit(user)}
          onRemove={user => this.props.removeUser(user)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ users: { list, error, isLoading } }) => ({
  isLoading,
  error,
  list
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(UserActionCreators, dispatch);
};

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  Form.create({ name: 'createUser' })
)(Users);
