import types from '../types';

const initialState = {
  user: null,
  isLoading: false,
  error: ''
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        error: ''
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
}
