import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

const TopBar = styled(Header)`
  background-color: rgb(154, 18, 179) !important;
  color: white;
`;

const ChildWrapper = styled.div`
  background-color: #fff;
  padding: 24;
`;

const LogoLink = styled(Link)`
  color: white;
  &:hover {
    color: white;
  }
`;

const App = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <TopBar>
        <LogoLink to="/">
          <div style={{ fontSize: 20 }}>
            <img
              src={require('../../assets/livee-white-min.png')}
              alt="Livee logo"
              style={{ height: 36, marginRight: 10 }}
            />
            Corporate CDN
          </div>
        </LogoLink>
      </TopBar>
      <Content style={{ padding: 50, backgroundColor: '#fff' }}>
        <ChildWrapper>{children}</ChildWrapper>
      </Content>
      <Footer style={{ textAlign: 'center' }}>LiveE ©2019</Footer>
    </Layout>
  );
};

export default App;
