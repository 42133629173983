import types from '../types';

const initialState = {
  list: []
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.ERROR_ADD:
      const error = {
        ...action.payload,
        id: Math.random()
          .toString(36)
          .substr(2, 9)
      };
      return { list: state.list.concat([error]) };
    case types.ERROR_REMOVE:
      return { list: state.list.filter(e => e.id !== action.payload.id) };
    default:
      return state;
  }
}
