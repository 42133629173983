import React from 'react';
import { Icon } from 'antd';

export default ({ style: { fontSize = 50, textAlign = 'center', ...styles } = {} }) => {
  return (
    <div
      style={{
        ...styles,
        textAlign,
        fontSize
      }}
    >
      <Icon type="loading" spin />
    </div>
  );
};
