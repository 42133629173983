import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { getPercentageOfTheDay, humanizeHour } from '../../../../../services/DatesService';

const Bar = styled.ul`
  padding-inline-start: 0;
`;

const UptimePeriod = styled.li`
  list-style-type: none;
  height: 30px;
  float: left;
`;

const getUptimeDowntimePeriod = (from, to, up) => {
  return {
    from,
    to,
    up,
    percent: getPercentageOfTheDay(from, to)
  };
};

const getDayUptimesDowntimes = downtimePeriods => {
  return downtimePeriods.reduce((acc, { from, to }, i) => {
    if (i - 1 >= 0) {
      acc = acc.concat([getUptimeDowntimePeriod(downtimePeriods[i - 1].to, from, true)]);
    }
    return [...acc, getUptimeDowntimePeriod(from, to, false)];
  }, []);
};

export default ({ t, downtimePeriods }) => {
  const percentages = getDayUptimesDowntimes(downtimePeriods);
  return (
    <Bar>
      {percentages.map(({ from, to, up, percent }, i) => (
        <UptimePeriod
          data-tip={t('instances:metrics:day:bar_hover', {
            from: humanizeHour(from),
            to: humanizeHour(to)
          })}
          key={i}
          style={{
            width: `${percent}%`,
            backgroundColor: up ? '#2cbd5f' : '#e24d42'
          }}
        />
      ))}
      <ReactTooltip />
    </Bar>
  );
};
