import { omit } from 'lodash/fp';
import types from '../types';
import InstancesService from '../../services/InstancesService';
import handleError from '../utils/errors';

export const getInstances = userId => dispatch => {
  dispatch({
    type: types.FIND_ALL_INSTANCES_REQUEST
  });
  return InstancesService.findAll(userId)
    .then(instances => {
      return dispatch({
        type: types.FIND_ALL_INSTANCES_SUCCESS,
        payload: instances
      });
    })
    .catch(err => handleError(types.FIND_ALL_INSTANCES_ERROR, err, dispatch));
};

export const createInstance = instance => dispatch => {
  return InstancesService.create(instance).then(createdInstance => {
    return dispatch({
      type: types.CREATE_INSTANCE_SUCCESS,
      payload: createdInstance
    });
  });
};

export const updateInstance = instance => dispatch => {
  const instanceToUpdate = omit(['id', 'agentVersion', 'status', 'user_id', 'createdAt'], instance);
  return InstancesService.update(instance.id, instanceToUpdate).then(updatedInstance => {
    return dispatch({
      type: types.UPDATE_INSTANCE_SUCCESS,
      payload: updatedInstance
    });
  });
};

export const removeInstance = instance => dispatch => {
  return InstancesService.remove(instance.id).then(() => {
    return dispatch({
      type: types.REMOVE_INSTANCE_SUCCESS,
      payload: instance
    });
  });
};
