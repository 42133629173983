import HttpService from './HttpService';

class AuthService extends HttpService {
  signin(data) {
    return this.http
      .post('/auth/login', data)
      .then(({ data, headers: { authorization } }) => {
        return { user: data, token: authorization };
      })
      .catch(this.onError);
  }
}

export default new AuthService();
