import React from 'react';
import { isEmpty } from 'lodash/fp';
import DayBar from './DayBar';
import DayDowntimes from './DayDowntimes';
import Loader from '../../../../utils/Loader';
import { humanizeDay } from '../../../../../services/DatesService';
import styled from 'styled-components';
import { subMinutes, parse } from 'date-fns';
import { Typography } from 'antd';
const { Title } = Typography;

const NoData = styled.div`
  text-align: center;
  margin-top: 50px;
  font-size: 22px;
`;

const RedCircle = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-bottom: 5px;
  margin-right: 10px;
  background-color: #e24d42;
  border: 2px solid #e24d42;
  border-radius: 15px;
  vertical-align: middle;
`;

const groupDowntimesByPeriod = downtimes => {
  return (
    downtimes
      // format the downtime period to { from, to, elapsed }
      .map(({ time, elapsed }) => {
        const to = parse(time);
        const from = subMinutes(to, elapsed);
        return {
          from,
          to,
          elapsed
        };
      })
  );
};

export default ({ t, selectedDay, downtimes: { list, isLoading } }) => {
  if (isLoading) {
    return <Loader />;
  }

  if (isEmpty(list)) {
    return <NoData>{t('instances:metrics:day:no_data', humanizeDay(selectedDay))}</NoData>;
  }

  const downtimePeriods = groupDowntimesByPeriod(list);
  return (
    <div style={{ marginTop: 70 }}>
      <Title level={2} style={{ marginBottom: 24 }}>
        <RedCircle />
        {t('instances:metrics:day:downtime_title', humanizeDay(selectedDay))}
      </Title>
      <DayBar t={t} downtimePeriods={downtimePeriods} />
      <DayDowntimes t={t} downtimePeriods={downtimePeriods} />
    </div>
  );
};
