import types from '../types';
import AuthService from '../../services/AuthService';
import { isEmpty } from 'lodash/fp';

const handleError = (type, e, dispatch) => {
  console.error(e);
  dispatch({ type, payload: e.message || e });
};

// Signin using login/password using the form
export const signIn = ({ login, password }) => dispatch => {
  dispatch({ type: types.LOGIN_REQUEST });
  return AuthService.signin({ login, password })
    .then(({ user, token }) => {
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      return dispatch({ type: types.LOGIN_SUCCESS, payload: user });
    })
    .catch(e => handleError(types.LOGIN_FAILURE, e, dispatch));
};

export const signInWithStorage = () => dispatch => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const token = localStorage.getItem('token') || '';
  return !isEmpty(user) && token !== ''
    ? dispatch({ type: types.LOGIN_SUCCESS, payload: user })
    : Promise.reject();
};

export const logout = () => dispatch => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  // TODO: handle it in the store
};
