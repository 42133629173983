import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { compose, isNil } from 'lodash/fp';

import Login from './components/auth/Login';
import App from './components/app/App';
import Users from './components/app/Users';
import Instances from './components/app/instances/Instances';
import InstanceMetrics from './components/app/instances/metrics/InstancesMetrics';

import { signInWithStorage } from './store/actions/auth';

let initialRoute = null;

const AppRoute = ({ component: Component, user, roles, ...rest }) => {
  const isAuthorized = !isNil(user) && roles.includes(user.role);
  return (
    <Route
      {...rest}
      render={props => {
        if (!initialRoute) {
          initialRoute = props.location.pathname;
        }
        return isAuthorized ? (
          <App>
            <Component {...props} role={user.role} />
          </App>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: initialRoute
              }
            }}
          />
        );
      }}
    />
  );
};

const Index = ({ role, ...props }) => {
  return role === 'admin' ? <Users {...props} /> : <Instances {...props} />;
};

class AppRouter extends Component {
  componentWillMount() {
    this.props.signInWithStorage();
  }

  render() {
    const { user } = this.props;
    return (
      <Router>
        <div>
          <AppRoute path="/" exact component={Index} user={user} roles={['admin', 'regular']} />
          <AppRoute
            path="/users/:userId/instances"
            exact
            component={Instances}
            user={user}
            roles={['admin']}
          />
          <AppRoute
            path="/users/:userId/instances/:instanceId"
            exact
            component={InstanceMetrics}
            user={user}
            roles={['admin', 'regular']}
          />
          <Route path="/login" exact component={Login} />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signInWithStorage }, dispatch);
};

export default compose(
  withTranslation(['common']),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppRouter);
