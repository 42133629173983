import axios from 'axios';

export default class HttpService {
  constructor() {
    this.http = axios.create({ baseURL: process.env.REACT_APP_API_URL, headers: {} });
    this.http.interceptors.request.use(
      config => {
        const token = localStorage.getItem('token') || '';
        config.headers['Authorization'] = `Bearer ${token || ''}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    this.http.interceptors.response.use(
      response => response,
      error => {
        if (401 === error.response.status) {
          localStorage.clear();
          window.location = '/login';
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  onResponse({ data }) {
    return data;
  }

  onError(error) {
    console.error(error);
    let message = 'network_error';
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          message = 'login_error';
          break;
        case 500:
          message = 'server_error';
          break;
        default:
          message = 'unknown_error';
          break;
      }
    }
    return Promise.reject({ message });
  }
}
