import React, { Component } from 'react';
import { compose } from 'lodash/fp';
import { Table, Input, InputNumber, Popconfirm, Form, Divider } from 'antd';
import { withTranslation } from 'react-i18next';
import LinkButton from './LinkButton';

const FormItem = Form.Item;
const EditableContext = React.createContext(undefined);

class EditableCell extends Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const { editing, dataIndex, title, inputType, record, index, ...restProps } = this.props;
    return (
      <EditableContext.Consumer>
        {form => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class EditableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingId: '',
      actionColumns: [
        {
          title: 'Actions',
          dataIndex: 'actions',
          render: (text, record) => {
            const { editingId } = this.props;
            const isEditing = this.isEditing(record);
            return (
              <div>
                {isEditing ? (
                  <span>
                    <EditableContext.Consumer>
                      {form => (
                        <LinkButton
                          style={{ marginRight: 8 }}
                          onClick={() => this.save(form, record)}
                        >
                          {this.props.t('common:form_actions:save')}
                        </LinkButton>
                      )}
                    </EditableContext.Consumer>
                    <LinkButton onClick={() => this.cancel()}>
                      {this.props.t('common:form_actions:cancel')}
                    </LinkButton>
                  </span>
                ) : (
                  <div>
                    <LinkButton disabled={editingId !== ''} onClick={() => this.edit(record.id)}>
                      {this.props.t('common:form_actions:edit')}
                    </LinkButton>
                    <Divider type="vertical" />
                    <Popconfirm
                      title={this.props.t('common:form_actions:delete_confirm')}
                      onConfirm={() => this.props.onRemove(record)}
                      okText={this.props.t('common:form_actions:confirm')}
                      cancelText={this.props.t('common:form_actions:cancel')}
                    >
                      <LinkButton>{this.props.t('common:form_actions:delete')}</LinkButton>
                    </Popconfirm>
                  </div>
                )}
              </div>
            );
          }
        }
      ]
    };
  }

  isEditing = record => record.id === this.props.editingId;

  cancel = () => {
    this.props.onEditing('');
  };

  save(form, record) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      this.props.onEditing('');
      this.props.onEdit({
        ...record,
        ...row
      });
    });
  }

  edit(id) {
    this.props.onEditing(id);
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    const columns = [...this.props.columns, ...this.state.actionColumns].map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          loading={this.props.loading}
          dataSource={this.props.data}
          rowKey="id"
          columns={columns}
          pagination={false}
        />
      </EditableContext.Provider>
    );
  }
}

export default compose(
  withTranslation(),
  Form.create({ name: 'editableForm' })
)(EditableTable);
