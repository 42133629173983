import HttpService from './HttpService';

class InstanceMetricsService extends HttpService {
  findUptimes(instanceId, userId) {
    return this.http
      .get(`/instances/${instanceId}/uptimes`, {
        params: {
          userId
        }
      })
      .then(({ data }) => data)
      .catch(this.onError);
  }

  findDowntimesOfDay(date, instanceId) {
    return this.http
      .get(`/instances/${instanceId}/uptimes/day`, {
        params: {
          date,
          tz: date.getTimezoneOffset()
        }
      })
      .then(({ data }) => data)
      .catch(this.onError);
  }
}

export default new InstanceMetricsService();
