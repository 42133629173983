import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import auth from './reducers/auth';
import users from './reducers/users';
import instances from './reducers/instances';
import instanceMetrics from './reducers/instanceMetrics';
import errors from './reducers/errors';

const middlewares = [thunk];
if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: true });
  middlewares.push(logger);
}

const reducers = combineReducers({
  auth,
  users,
  instances,
  instanceMetrics,
  errors
});

const store = createStore(reducers, compose(applyMiddleware(...middlewares)));
export default store;
