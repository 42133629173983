import { omit } from 'lodash/fp';
import types from '../types';
import UsersService from '../../services/UsersService';
import handleError from '../utils/errors';

export const getUsers = () => dispatch => {
  dispatch({
    type: types.FIND_ALL_USERS_REQUEST
  });
  return UsersService.findAll()
    .then(users => {
      return dispatch({
        type: types.FIND_ALL_USERS_SUCCESS,
        payload: users
      });
    })
    .catch(err => handleError(types.FIND_ALL_USERS_ERROR, err, dispatch));
};

export const createUser = user => dispatch => {
  return UsersService.create(user).then(createdUser => {
    return dispatch({
      type: types.CREATE_USER_SUCCESS,
      payload: createdUser
    });
  });
};

export const updateUser = user => dispatch => {
  const userToUpdate = omit(['id', 'createdAt', 'role'], user);
  return UsersService.update(user.id, userToUpdate).then(updatedUser => {
    return dispatch({
      type: types.UPDATE_USER_SUCCESS,
      payload: updatedUser
    });
  });
};

export const removeUser = user => dispatch => {
  return UsersService.remove(user.id).then(() => {
    return dispatch({
      type: types.REMOVE_USER_SUCCESS,
      payload: user
    });
  });
};
