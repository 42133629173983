import styled from 'styled-components';

export default styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #1890ff;
  cursor: pointer;
  outline: none;
`;
