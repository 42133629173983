import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { bindActionCreators } from 'redux';
import { Col } from 'antd';
import * as InstanceMetricsActionCreators from '../../../../store/actions/instanceMetrics';
import DayMetrics from './day/DayMetrics';
import YearHeatmap from './year/YearHeatmap';
import './InstancesMetrics.css';

export class InstanceMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { userId, instanceId } = this.props.match.params;
    this.setState(
      {
        userId,
        instanceId
      },
      () => {
        const { instanceId, userId } = this.state;
        this.props.findUptimes(instanceId, userId);
        this.props.findDowntimesOfDay(this.props.selectedDay, instanceId);
      }
    );
  }

  render() {
    const { t, uptimes, downtimesOfDay, selectedDay, selectDay } = this.props;
    return (
      <Col span={16} offset={4}>
        <YearHeatmap
          t={t}
          uptimes={uptimes}
          onDaySelected={day => selectDay(day, this.state.instanceId)}
        />
        <DayMetrics t={t} downtimes={downtimesOfDay} selectedDay={selectedDay} />
      </Col>
    );
  }
}

const mapStateToProps = ({ instanceMetrics: { uptimes, selectedDay, downtimesOfDay } }) => ({
  uptimes,
  selectedDay,
  downtimesOfDay
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(InstanceMetricsActionCreators, dispatch);
};

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InstanceMetrics);
