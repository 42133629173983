import types from '../types/errors';

export const handleError = (type, error, dispatch) => {
  console.error(error);
  // Each action can handle the error (stop loading, etc ...)
  dispatch({ type, payload: error });
  // And the notification system will receive it too:
  dispatch({ type: types.ERROR_ADD, payload: { text: `common:errors.${error.message}` } });
};

export default handleError;
