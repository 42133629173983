import { differenceInSeconds, format, getHours } from 'date-fns';

const SECONDS_IN_A_DAY = 3600 * 24;

const leftPadZero = str => {
  // ('0' + '2').slice(-2) => '02'
  // ('0' + '10').slice(-2) => '10'
  return ('0' + str).slice(-2);
};

export const humanizeDay = date => {
  return {
    day: leftPadZero(date.getDate()),
    month: leftPadZero(date.getUTCMonth() + 1),
    year: date.getUTCFullYear()
  };
};

export const humanizeHour = date => {
  return format(date, 'H:mm:ss');
};

export const humanizeElapsedMinutes = minutes => {
  if (minutes < 60) {
    return `${minutes}min`;
  }
  return `${Math.floor(minutes / 60)}h${minutes % 60}min`;
};

export const getPercentageOfTheDay = (from, to) => {
  return Math.abs((differenceInSeconds(from, to) * 100) / SECONDS_IN_A_DAY);
};
