import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notification } from 'antd';
import { withTranslation } from 'react-i18next';
import { compose } from 'lodash/fp';

import { removeError } from '../../store/actions/errors';

class Errors extends Component {
  componentDidUpdate() {
    const { errors, t, removeError } = this.props;
    errors.forEach(({ id, text }) => {
      notification.error({
        message: t('common:errors:title'),
        description: t(text),
        onClose: () => removeError(id)
      });
    });
  }
  render() {
    return null;
  }
}

const mapStateToProps = ({ errors: { list } }) => ({
  errors: list
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ removeError }, dispatch);
};

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Errors);
