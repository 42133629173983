import HttpService from './HttpService';

class UsersService extends HttpService {
  create(payload) {
    return this.http
      .post('/users', payload)
      .then(({ data }) => data)
      .catch(this.onError);
  }

  findAll() {
    return this.http
      .get('/users')
      .then(({ data }) => data)
      .catch(this.onError);
  }

  update(id, payload) {
    return this.http
      .put(`/users/${id}`, payload)
      .then(({ data }) => data)
      .catch(this.onError);
  }

  remove(id) {
    return this.http
      .delete(`/users/${id}`)
      .then(({ data }) => data)
      .catch(this.onError);
  }
}

export default new UsersService();
