import React from 'react';
import styled from 'styled-components';
import { Tag, Icon } from 'antd';
import { humanizeHour, humanizeElapsedMinutes } from '../../../../../services/DatesService';

const DownTag = styled(Tag)`
  vertical-align: text-top !important;
`;

const DateDisplay = styled.span`
  font-size: 22px;
  font-weight: 700;
`;

const RightIcon = styled(Icon)`
  font-size: 10px;
  margin: 0 10px;
  vertical-align: 1px !important;
`;

const renderDowntimeLines = (t, downtimePeriods) => {
  return downtimePeriods.map(({ from, to, elapsed }) => {
    return (
      <div key={+from} style={{ lineHeight: '50px' }}>
        <DownTag color="red">{t('instances:metrics:day:downtime_tag')}</DownTag>
        <span>
          <DateDisplay style={{ marginLeft: 10 }}>{humanizeHour(from)}</DateDisplay>{' '}
          <RightIcon type="right" />
          <DateDisplay style={{ marginRight: 10 }}>{humanizeHour(to)}</DateDisplay>
          <span style={{ verticalAlign: 'baseline' }}>({humanizeElapsedMinutes(elapsed)})</span>
        </span>
      </div>
    );
  });
};

export default ({ t, downtimePeriods }) => {
  return (
    <div style={{ marginTop: 70 }}>
      <div style={{ fontSize: 18 }}>{renderDowntimeLines(t, downtimePeriods)}</div>
    </div>
  );
};
