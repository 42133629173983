import React, { Component } from 'react';
import { Row, Col, Form, Icon, Input, Button } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';

import { signIn } from '../../store/actions/auth';

const LoginForm = styled(Form)`
  background-color: #fff;
  text-align: center;
  padding: 20px !important;
  margin-top: 50px !important;
  border-radius: 10px;
`;

const ErrorText = styled.div`
  color: red;
  font-weight: bold;
  margin: 5px;
`;

const Logo = styled.img`
  max-width: 100%;
  height: auto;
`;

class Login extends Component {
  componentDidMount() {
    document.body.classList.toggle('livee-purple', true);
  }
  componentWillUnmount() {
    document.body.classList.remove('livee-purple');
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.signIn(values);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      user,
      isLoading,
      error,
      t,
      location: { state }
    } = this.props;
    if (user) {
      return <Redirect to={state && state.from ? this.props.location.state.from : '/'} />;
    }
    return (
      <Row>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 7 }} lg={{ span: 6, offset: 9 }}>
          <Row>
            <Col style={{ textAlign: 'center', marginTop: 50 }}>
              <Logo src={require('../../assets/livee-white.png')} style={{ height: 100 }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <LoadingOverlay active={isLoading} spinner text={t('common:loading')}>
                <LoginForm onSubmit={this.handleSubmit} className="login-form">
                  <h2>{t('auth:title')}</h2>
                  <p>{t('auth:description')}</p>
                  {error !== '' && (
                    <Row>
                      <Col style={{ textAlign: 'center' }}>
                        <ErrorText>{t(`common:errors.${error}`)}</ErrorText>
                      </Col>
                    </Row>
                  )}
                  <Form.Item>
                    {getFieldDecorator('login', {
                      rules: [{ required: true, message: t('auth:login_error') }]
                    })(
                      <Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('auth:login')}
                        autoComplete="login"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: t('auth:password_error') }]
                    })(
                      <Input
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder={t('auth:password')}
                        autoComplete="current-password"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      {t('auth:submit')}
                    </Button>
                  </Form.Item>
                </LoginForm>
              </LoadingOverlay>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ auth: { user, isLoading, error } }) => ({
  user,
  isLoading,
  error
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn }, dispatch);
};

export default compose(
  withTranslation(['auth, common']),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  Form.create({ name: 'login' })
)(Login);
