import types from '../types';

const initialState = {
  list: [],
  isLoading: false,
  error: ''
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.FIND_ALL_INSTANCES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };

    case types.FIND_ALL_INSTANCES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false
      };

    case types.FIND_ALL_INSTANCES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case types.CREATE_INSTANCE_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload]
      };

    case types.UPDATE_INSTANCE_SUCCESS:
      return {
        ...state,
        list: state.list.map(instance => {
          return instance.id === action.payload.id ? action.payload : instance;
        })
      };

    case types.REMOVE_INSTANCE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(instance => instance.id !== action.payload.id)
      };

    default:
      return state;
  }
}
