import HttpService from './HttpService';

class InstancesService extends HttpService {
  create(payload) {
    return this.http
      .post('/instances', payload)
      .then(({ data }) => data)
      .catch(this.onError);
  }

  findAll(userId) {
    return this.http
      .get('/instances', {
        params: {
          userId,
          withStatus: true
        }
      })
      .then(({ data }) => data)
      .catch(this.onError);
  }

  update(id, payload) {
    return this.http
      .put(`/instances/${id}`, payload)
      .then(({ data }) => data)
      .catch(this.onError);
  }

  remove(id) {
    return this.http
      .delete(`/instances/${id}`)
      .then(({ data }) => data)
      .catch(this.onError);
  }
}

export default new InstancesService();
