import types from '../types';

const initialState = {
  list: [],
  isLoading: false,
  error: ''
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case types.FIND_ALL_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };

    case types.FIND_ALL_USERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false
      };

    case types.FIND_ALL_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload]
      };

    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        list: state.list.map(user => {
          return user.id === action.payload.id ? action.payload : user;
        })
      };

    case types.REMOVE_USER_SUCCESS:
      return {
        ...state,
        list: state.list.filter(user => user.id !== action.payload.id)
      };

    default:
      return state;
  }
}
