import React from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
// eslint-disable-next-line
import ReactTooltip from 'react-tooltip';
import { range, isEmpty, head } from 'lodash/fp';
import styled from 'styled-components';
import Loader from '../../../../utils/Loader';
import { Icon, Typography } from 'antd';
import { humanizeDay } from '../../../../../services/DatesService';
const Title = Typography.Title;

const CalendarIcon = styled(Icon)`
  font-size: 25px;
  margin-right: 10px;
  vertical-align: -2px !important;
`;

const getTooltipValue = t => ({ date, count }) => {
  if (!date) {
    return { 'data-tip': '' };
  }
  return {
    'data-tip': t('instances:metrics:year:tooltip', {
      ...humanizeDay(date),
      uptime: count.toFixed(4)
    })
  };
};

const getClassForValue = value => {
  if (!value || value.count === 0) {
    return 'color-empty';
  }
  const { count } = value;
  const color = count < 99.9 ? 1 : count < 99.99 ? 2 : count < 99.999 ? 3 : 4;
  return `color-scale-${color}`;
};

const parseUptimes = uptimeList => {
  return uptimeList.map(point => {
    return {
      date: point.date,
      count: point.uptime
    };
  });
};

const oneYearBefore = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

export default ({ t, uptimes: { list, isLoading, error }, onDaySelected }) => {
  if (isLoading) {
    return <Loader />;
  }

  const startDate = isEmpty(list) ? oneYearBefore() : head(list).date;

  return (
    <>
      <Title level={2} style={{ marginBottom: 20 }}>
        <CalendarIcon type="calendar" />
        {t('instances:metrics:year:heatmap_title')}
      </Title>
      <CalendarHeatmap
        startDate={startDate}
        endDate={new Date()}
        values={parseUptimes(list)}
        classForValue={getClassForValue}
        monthLabels={range(0, 12).map(i => t(`instances:metrics:months:short:${i}`))}
        tooltipDataAttrs={getTooltipValue(t)}
        showWeekdayLabels={false}
        onClick={value => {
          if (value) {
            onDaySelected(value.date);
          }
        }}
      />
    </>
  );
};
